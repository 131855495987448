import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box, TextField } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './FolderCard.module.css';
import { Folder } from '../../../services/api/workspace/types';
import WorkspaceItemMenu from '../WorkspaceItemMenu';
import { useDeleteFolder, useUpdateFolder } from '../../../services/api/workspace/folders/hooks';
import { useDeleteItemConfirm, useToggle } from '../../../hooks';
import { noop } from '../../../services/utils';

interface FolderCardProps {
  folder: Folder;
  selectedFolderId?: string;
  onCardClick: (folder: Folder) => void;
}

const FolderCard: React.FC<FolderCardProps> = ({ folder, selectedFolderId, onCardClick }) => {
  const deleteFolderConfirm = useDeleteItemConfirm();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [folderName, setFolderName] = useState(folder.name);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditMode, toggleEditMode] = useToggle(false);

  const { mutate: deleteFolder } = useDeleteFolder();
  const { mutate: updateFolder } = useUpdateFolder();

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  const onEditSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateFolder({ folderId: folder.id, payload: { name: folderName } });
      toggleEditMode();
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteFolder = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    deleteFolderConfirm('Are you sure you want to delete this theme?', folder.name)
      .then(() => deleteFolder(folder.id))
      .catch(noop);
  };

  const onEdit = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    toggleEditMode();
  };

  return (
    <Card
      onClick={() => onCardClick(folder)}
      style={{ minWidth: folder.name.length > 22 ? 'fit-content' : 300 }}
      className={`${styles.folderCard} ${selectedFolderId === folder.id ? styles.folderCardSelected : ''}`}
    >
      <CardContent className={styles.cardContent}>
        <Box>
          {isEditMode ? (
            <TextField
              size="small"
              variant="standard"
              value={folderName}
              onKeyDown={onEditSubmit}
              onChange={(event) => setFolderName(event.target.value)}
              className={styles.input}
              inputRef={inputRef}
            />
          ) : (
            <Typography variant="h6">{folder.name}</Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            {folder.topic_extracts} Topics | {folder.people_extracts} People
          </Typography>
        </Box>
        <IconButton onClick={handleOpenMenu}>
          <MoreVertIcon color="primary" />
        </IconButton>
        <WorkspaceItemMenu
          onClose={handleCloseMenu}
          onEdit={onEdit}
          anchorEl={anchorEl}
          onDelete={handleDeleteFolder}
        />
      </CardContent>
    </Card>
  );
};

export default FolderCard;
