import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SnackbarType } from '../../../../../enums';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { addTopicToFolder } from '../requests';

const useAddTopicToFolder = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({
      folderId,
      topicExtractId,
    }: {
      folderId: string;
      topicExtractId: string;
    }) => addTopicToFolder(folderId, topicExtractId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fullSearch'] });
      showSnackbar(Messages.SUCCESS.FOLDER_TOPIC_ADDED, SnackbarType.SUCCESS);
    },
  });
};

export default useAddTopicToFolder;
