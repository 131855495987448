import React, { useState } from 'react';
import {
  Typography,
  Toolbar,
  Icon,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Button,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SearchIcon from '@mui/icons-material/Search';
import styles from './TrendingTopics.module.css';
import { TopicResult } from '../../../services/api/search/types';
import {
  Field,
  defaultRowsPerPage,
  exportFileName,
  exportHeaders,
  filterTopicsByKeyword,
  flattenTopicsExtracts,
} from './TrendingTopics.utils';
import { useTableUtils, useToggle } from '../../../hooks';
import PickFolderModal from '../PickFolderModal/PickFolderModal';

import AddToWorkspaceButton from '../AddToWorkspaceButton';
import {
  exportToCsv,
  exportToExcel,
  getDomainName,
  pickMatchScoreLabelColor,
} from '../../../services/utils';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { useRunSearch } from '../../../services/api/search/hooks';
import { Link } from 'react-router-dom';
import { useCreateFolder, useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import {
  useAddTopicToFolder,
  useDeleteFolderTopicByExtract,
} from '../../../services/api/workspace/topics/hooks';

interface TrendingTopicsProps {
  topics: TopicResult[];
}

const TrendingTopics: React.FC<TrendingTopicsProps> = ({ topics }) => {
  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);

  const { mutate: runSearch } = useRunSearch();
  const { mutate: createFolder } = useCreateFolder();
  const { mutate: addTopicToFolder } = useAddTopicToFolder();
  const { mutate: deleteTopicFromFolder } = useDeleteFolderTopicByExtract();
  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const flattenedTopics = flattenTopicsExtracts(topics);

  const {
    data: paginatedTopics,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
  } = useTableUtils(flattenedTopics, defaultRowsPerPage, filterTopicsByKeyword);

  const exportRows = flattenedTopics.map((topic) => [topic.name, topic.description]);

  const onFolderSelect = (folderId: string) => {
    if (selectedTopicId) {
      addTopicToFolder({ folderId, topicExtractId: selectedTopicId });
    }
  };

  const handleAddTopicToFolder = (topicId: string) => {
    setSelectedTopicId(topicId);
    togglePickFolderModalOpen();
  };

  const handleAddNewFolder = (searchTerm: string) => {
    createFolder(searchTerm);
  };

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <Toolbar>
        <Icon color="inherit" aria-label="menu" className={styles.titleIcon}>
          <ChatIcon />
        </Icon>

        <Typography className={styles.title} variant="h6" component="div">
          Trending Topics
        </Typography>
      </Toolbar>
      <Box className={styles.content}>
        <TableToolbar
          onFilter={onFilter}
          onExportCSV={handleExportCSV}
          onExportExcel={handleExportExcel}
        />

        {foldersFetched && (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <SortableTableCell
                      columnKey="name"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Topic
                    </SortableTableCell>
                    <TableCell>Actions</TableCell>
                    <SortableTableCell
                      columnKey="description"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                    >
                      Description
                    </SortableTableCell>
                    <SortableTableCell
                      columnKey="matchScore"
                      hoveredColumn={hoveredColumn}
                      sortConfig={sortConfig}
                      setHoveredColumn={setHoveredColumn}
                      requestSort={requestSort}
                      tableCellContentStyle={{ marginLeft: 24 }}
                      align="center"
                    >
                      Relevancy score
                    </SortableTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedTopics.map((topic) => (
                    <TableRow key={topic.id}>
                      <TableCell>
                        <Typography fontWeight={600} variant="body2">
                          {topic.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box className={styles.actions}>
                          <AddToWorkspaceButton
                            onRemove={() => deleteTopicFromFolder(topic.id)}
                            onAdd={() => handleAddTopicToFolder(topic.id)}
                            defaultValue={topic.addedToFolder}
                            buttonClassName={styles.addToWorkspaceButton}
                          />
                          <Tooltip placement="top" title="Run search for this subtopic">
                            <Button
                              variant="outlined"
                              color="info"
                              size="small"
                              onClick={() => runSearch(topic[Field.NAME])}
                            >
                              <SearchIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography mb={0.5} variant="body2">
                          {topic.description}
                        </Typography>
                        <Link
                          to={topic.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.link}
                        >
                          {getDomainName(topic.url)}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          color={pickMatchScoreLabelColor(topic.matchScore)}
                          fontWeight={600}
                          variant="body2"
                        >
                          {Math.trunc(topic.matchScore)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={flattenedTopics.length}
              rowsPerPage={rowsPerPage}
              page={page}
              defaultValue={defaultRowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <PickFolderModal
              open={pickFolderModalOpen}
              onClose={togglePickFolderModalOpen}
              onSelect={onFolderSelect}
              folders={folders}
              onAddFolder={handleAddNewFolder}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default TrendingTopics;
