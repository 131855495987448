import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Link,
  TextField,
  SelectChangeEvent,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styles from './TopicDetailsModal.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import NotesSection from '../NotesSection/NotesSection';
import DetailsModalActions from '../DetailsModalActions/DetailsModalActions';
import ItemInfoLabel from '../ItemInfoLabel';
import WorkspaceItemMenu from '../WorkspaceItemMenu';

import { useDeleteItemConfirm } from '../../../hooks';
import { getDomainName, noop } from '../../../services/utils';
import { Priorities } from '../../../enums';
import {
  useCreateTopicNote,
  useFetchTopicNotes,
} from '../../../services/api/workspace/notes/hooks';
import {
  useFetchTopicNotesAttachments,
  useUploadNoteAttachmentToTopic,
} from '../../../services/api/workspace/notesAttachments/hooks';
import {
  useDeleteFolderTopic,
  useFetchTopicDetails,
  useUpdateTopicDetails,
} from '../../../services/api/workspace/topics/hooks';
import { useManageTopicTags } from '../../../services/api/workspace/tags/hooks';
import { useUserSettingsStore } from '../../../stores';

interface TopicDetailsModalProps {
  open: boolean;
  onClose: () => void;
  isDefaultEditMode: boolean;
  toggleMoveToModal: () => void;
  topicExtractId: string;
}

const TopicDetailsModal: React.FC<TopicDetailsModalProps> = ({
  open,
  onClose,
  isDefaultEditMode,
  topicExtractId,
  toggleMoveToModal,
}) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const deleteTopicConfirm = useDeleteItemConfirm();

  const [isEditMode, setIsEditMode] = useState(false);
  const [title, setTitle] = useState('');

  const [noteText, setNoteText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: notes } = useFetchTopicNotes({
    topic_extract_id: topicExtractId,
  });
  const { data: notesAttachments } = useFetchTopicNotesAttachments(topicExtractId, {});

  const { data: topicDetails, refetch } = useFetchTopicDetails(selectedFolderId, topicExtractId);
  const { mutate: uploadNoteAttachmentToTopic } = useUploadNoteAttachmentToTopic();

  const { mutate: deleteTopic } = useDeleteFolderTopic();
  const { mutate: createTopicNote } = useCreateTopicNote();
  const { mutate: updateTopicDetails } = useUpdateTopicDetails();
  const { mutate: manageTopicTags } = useManageTopicTags();

  const topicId = topicDetails?.id || '';

  useEffect(() => {
    setIsEditMode(isDefaultEditMode);
  }, [isDefaultEditMode]);

  useEffect(() => {
    if (topicDetails) {
      setTitle(topicDetails.name);
    }
  }, [topicDetails]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteTopic = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    deleteTopicConfirm('Are you sure you want to delete this topic?', topicDetails?.name || '')
      .then(() => deleteTopic(topicId, { onSuccess: () => onClose() }))
      .catch(noop);
  };

  const handleMoveTo = () => {
    onClose();
    toggleMoveToModal();
  };

  const handleCreateTextNote = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (noteText.trim().length) {
      createTopicNote({
        topic_extract_id: topicExtractId,
        text: noteText,
      });
      setNoteText('');
    }
  };

  const handleChangePriority = (event: SelectChangeEvent<string>) => {
    updateTopicDetails({
      topicId,
      payload: { priority: event.target.value as Priorities },
    });
  };

  const handleManageTags = (tagsIdsToAdd: string[], tagsIdsToRemove: string[]) => {
    manageTopicTags({
      topicExtractId: topicExtractId,
      tagsIdsToAdd,
      tagsIdsToRemove,
    });
  };

  const handleSubmitEdit = () => {
    updateTopicDetails(
      { topicId, payload: { name: title } },
      { onSuccess: () => setIsEditMode(false) },
    );
  };

  const tags =
    topicDetails?.folder_tags.map((folderTag) => ({
      ...folderTag.folder_tag,
      tagExtractId: folderTag.id,
    })) || [];

  const refetchTopicDetails = () => {
    refetch();
  };

  const handleUploadNoteAttachment = (file: File) => {
    uploadNoteAttachmentToTopic({ topicExtractId, file });
  };

  return (
    <Dialog
      PaperProps={{
        className: styles.dialogPaper,
      }}
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
    >
      {topicDetails && (
        <DialogContent>
          <Box mb={4}>
            <Box className={styles.titleBox}>
              {isEditMode ? (
                <TextField
                  size="small"
                  variant="standard"
                  value={title}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmitEdit();
                    }
                  }}
                  onChange={(event) => setTitle(event.target.value)}
                  className={styles.titleInput}
                />
              ) : (
                <Typography className={styles.titleText} variant="h5">
                  {title}
                </Typography>
              )}

              <Box display="flex">
                <IconButton onClick={handleOpenMenu}>
                  <MoreVertIcon color="primary" />
                </IconButton>
                <IconButton onClick={onClose}>
                  <CloseIcon color="primary" />
                </IconButton>
              </Box>
            </Box>
            <Link
              display="flex"
              alignItems="center"
              color="primary"
              href={topicDetails?.source_url}
              target="_blank"
            >
              <OpenInNewIcon color="primary" />
              <Typography ml={1}>{getDomainName(topicDetails.source_url)}</Typography>
            </Link>
          </Box>
          <DetailsModalActions
            priorityValue={topicDetails.priority}
            onPriorityChange={handleChangePriority}
            tags={tags}
            manageTags={handleManageTags}
            refetchItemDetails={refetchTopicDetails}
          />

          <ItemInfoLabel
            style={{ marginBottom: 16 }}
            title="Relevancy score"
            value={topicDetails.relevancy_score}
          />

          <Typography fontWeight="700" variant="body2" color="textSecondary" mb={1}>
            Description
          </Typography>
          <Typography mb={2}>{topicDetails.topic_extract.reasoning}</Typography>

          <NotesSection
            noteText={noteText}
            onChange={setNoteText}
            notes={notes}
            onSubmit={handleCreateTextNote}
            notesAttachments={notesAttachments}
            uploadNoteAttachment={handleUploadNoteAttachment}
            topicExtractId={topicExtractId}
          />
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      <WorkspaceItemMenu
        onDelete={handleDeleteTopic}
        onEdit={() => setIsEditMode((prev) => !prev)}
        onMoveTo={handleMoveTo}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      />
    </Dialog>
  );
};

export default TopicDetailsModal;
