import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addPersonToFolder } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { SnackbarType } from '../../../../../enums';
import { Messages } from '../../../../../constants';

const useAddPersonToFolder = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({
      folderId,
      personExtractId,
    }: {
      folderId: string;
      personExtractId: string;
    }) => addPersonToFolder(folderId, personExtractId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fullSearch'] });
      showSnackbar(Messages.SUCCESS.FOLDER_PERSON_ADDED, SnackbarType.SUCCESS);
    },
  });
};

export default useAddPersonToFolder;
