import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, Paper, Typography, Container, Link } from '@mui/material';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import { useAuth } from '../../services/auth';
import styles from './Login.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState(false); // state for displaying the warning
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add(styles.loginBackground);

      // Check the current domain
      const currentDomain = window.location.hostname;
      if (currentDomain === 'sia-ai.link') {
        setShowWarning(true); // Show the warning if domain is sia-link.com
      }

    return () => {
      document.body.classList.remove(styles.loginBackground);
    };
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await auth.signIn(email.trim(), password.trim());
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <Container component="main" className={styles.mainContainer}>
      <Grid container className={styles.gridContainer} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          container
          alignItems="center"
          justifyContent="center"
        >
          <BlurOnRoundedIcon className={styles.icon} />
        </Grid>
        <Grid item xs={12} sm={8} md={6} container alignItems="center" justifyContent="center">
          <Paper elevation={6} className={styles.paper}>
            <Typography variant="h5" component="h3" gutterBottom>
              EventProducer Pro
            </Typography>
            {/* Conditional warning text */}
            {showWarning && (
              <Typography variant="body2" color="error" align="left" gutterBottom>
                We have officially changed our domain from <Link href="https://sia-ai.link" target="_blank" rel="noopener noreferrer">sia-ai.link</Link> to <Link href="https://app.eventproducerpro.com" target="_blank" rel="noopener noreferrer">app.eventproducerpro.com</Link>  <br />
                All of your data, settings, and account information will remain exactly the same. <br />
                Please update your bookmarks and saved links to ensure a smooth transition.
              </Typography>
            )}
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                Login
              </Button>
              <Grid container justifyContent="center" alignItems="center">
                <Link className={styles.forgotPassword} href="/password-reset">
                  Forgot your password?
                </Link>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
