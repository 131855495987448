import React from 'react';
import { Menu, MenuItem, ListItemText } from '@mui/material';

interface WorkspaceItemMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onEdit: (event: React.MouseEvent<HTMLLIElement>) => void;
  onDelete: (event: React.MouseEvent<HTMLLIElement>) => void;
  onMoveTo?: () => void;
}

const WorkspaceItemMenu: React.FC<WorkspaceItemMenuProps> = ({
  anchorEl,
  onClose,
  onEdit,
  onDelete,
  onMoveTo,
}) => {
  const handleEdit = (event: React.MouseEvent<HTMLLIElement>) => {
    onEdit(event);
    onClose();
  };

  const handleDelete = (event: React.MouseEvent<HTMLLIElement>) => {
    onDelete(event);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={handleEdit}>
        <ListItemText primary="Edit" />
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <ListItemText primary="Remove" />
      </MenuItem>

      {onMoveTo && (
        <MenuItem onClick={onMoveTo}>
          <ListItemText primary="Move to..." />
        </MenuItem>
      )}
    </Menu>
  );
};

export default WorkspaceItemMenu;
