import React, { useEffect, useState } from 'react';
import { Menu, Box, IconButton, Button, Typography, Checkbox } from '@mui/material';
import TagIndicator from '../TagIndicator/TagIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TagCustomizer from '../TagCustomizer/TagCustomizer';
import { DotColors } from '../../../constants/tagColorPalette';
import useFetchTags from '../../../services/api/workspace/tags/hooks/useFetchTags';
import { FolderTag, Tag } from '../../../services/api/workspace/types';
import {
  useCreateTag,
  useDeleteTag,
  useUpdateTag,
} from '../../../services/api/workspace/tags/hooks';
import styles from './TagManagementMenu.module.css';

interface TagManagemenuMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  manageTags: (idsToAdd: string[], idsToRemove: string[]) => void;
  initialSelectedTags: FolderTag[];
}

type MenuMode = 'view' | 'add' | 'edit';

export type TagType = {
  color: string;
  name: string;
};

interface MappedTag {
  selected: boolean;
  changed?: boolean;
  id: string;
  name: string;
  color: string;
  created_at: string;
  updated_at: string;
  project_id: string;
  folder_tags_relations: string[];
}

const TagManagemenuMenu: React.FC<TagManagemenuMenuProps> = ({
  anchorEl,
  open,
  onClose,
  manageTags,
  initialSelectedTags,
}) => {
  const [menuMode, setMenuMode] = useState<MenuMode>('view');
  const [editableTag, setEditableTag] = useState<Tag | null>(null);
  const [mappedTags, setMappedTags] = useState<MappedTag[]>([]);

  const { data: tags } = useFetchTags({});
  const { mutate: createTag } = useCreateTag();
  const { mutate: updateTag } = useUpdateTag();
  const { mutate: deleteTag } = useDeleteTag();

  useEffect(() => {
    if (open && tags) {
      const tagsChanged =
        tags.length !== mappedTags.length ||
        tags.some(
          (tag, index) =>
            tag.name !== mappedTags[index]?.name || tag.color !== mappedTags[index]?.color,
        );

      if (tagsChanged) {
        const newMappedTags: MappedTag[] = tags.map((tag) => ({
          ...tag,
          selected: initialSelectedTags.some((selectedTag) => selectedTag.id === tag.id),
          changed: false,
        }));
        setMappedTags(newMappedTags);
      }
    }
  }, [open, tags, initialSelectedTags, mappedTags]);

  const handleAddClick = () => {
    setMenuMode('add');
  };

  const handleEditClick = (tag: Tag) => {
    setEditableTag(tag);
    setMenuMode('edit');
  };

  const handleBack = () => {
    setMenuMode('view');
    setEditableTag(null);
  };

  const handleSave = (tag: TagType, id: string | null) => {
    if (menuMode === 'add') {
      createTag(tag);
    } else if (menuMode === 'edit' && editableTag) {
      updateTag({
        tagId: id || '',
        payload: { color: tag.color, name: tag.name },
      });
    }
    handleBack();
  };

  const handleDelete = (id: string) => {
    deleteTag(id);
  };

  const handleTagSelect = (tagId: string) => {
    setMappedTags((prev) =>
      prev.map((tag) =>
        tag.id === tagId ? { ...tag, selected: !tag.selected, changed: true } : tag,
      ),
    );
  };

  const handleCloseMenu = () => {
    const tagsToAdd = mappedTags.filter((tag) => tag.selected && tag.changed).map((tag) => tag.id);
    const tagsToRemove = mappedTags
      .filter((tag) => !tag.selected && tag.changed)
      .map((tag) => tag.id);
    manageTags(tagsToAdd, tagsToRemove);
    setMappedTags([]);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu} autoFocus={false}>
      <Box className={styles.container}>
        {menuMode === 'view' ? (
          <>
            {mappedTags.map((tag) => (
              <Box className={styles.tagRow} key={tag.id}>
                <Box onClick={() => handleTagSelect(tag.id)} className={styles.tag}>
                  <Checkbox checked={tag.selected} onChange={() => {}} color="primary" />
                  <Box style={{ width: 160 }}>
                    <TagIndicator
                      backgroundColor={tag.color}
                      dotColor={DotColors[tag.color]}
                      label={tag.name}
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                </Box>

                <IconButton onClick={() => handleEditClick(tag)}>
                  <EditIcon color="disabled" />
                </IconButton>
                <IconButton onClick={() => handleDelete(tag.id)}>
                  <DeleteIcon color="disabled" />
                </IconButton>
              </Box>
            ))}
            <Box display="flex" width="100%">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleAddClick}
                className={styles.addTagButton}
              >
                <Typography variant="body2">Add tag</Typography>
              </Button>
            </Box>
          </>
        ) : (
          <TagCustomizer
            initialTag={editableTag}
            onSave={handleSave}
            onClose={onClose}
            onBack={handleBack}
          />
        )}
      </Box>
    </Menu>
  );
};

export default TagManagemenuMenu;
