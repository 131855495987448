import { Priorities } from '../../../../enums';
import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { TopicDetails } from '../types';

export const addTopicToFolder = (folderId: string, topicExtractId: string) => {
  return apiClient
    .post(`${BASE_API_URL}/folders/${folderId}/topic_extracts/${topicExtractId}`)
    .then((response) => response.data);
};

export const deleteFolderTopic = (topicId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/folders/topic_extracts/${topicId}`)
    .then((response) => response.data);
};

export const deleteFolderTopicByExtract = (topicExtractId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/folders/topic_extracts/extract/${topicExtractId}`, {})
    .then((response) => response.data);
};

export const fetchTopicDetails = (
  folder_id: string,
  topic_extract_id: string,
): Promise<TopicDetails> => {
  return apiClient
    .get(`${BASE_API_URL}/folders/${folder_id}/topic_extracts/${topic_extract_id}`)
    .then((response) => response.data);
};

export const updateTopicDetails = (
  topicId: string,
  payload: { name?: string; priority?: Priorities },
) => {
  return apiClient
    .put(`${BASE_API_URL}/folders/topic_extracts/${topicId}`, payload)
    .then((response) => response.data);
};

export const moveTopicToAnotherFolder = ({
  folderId,
  extractId,
  folderIdDestination,
}: {
  folderId: string;
  extractId: string;
  folderIdDestination: string;
}) => {
  return apiClient
    .put(
      `${BASE_API_URL}/folders/${folderId}/topic_extracts/${extractId}/move-to-folder/${folderIdDestination}`,
    )
    .then((response) => response.data);
};

export const moveTopicToAnotherGroup = ({
  folderId,
  extractId,
  groupIdDestination,
}: {
  folderId: string;
  extractId: string;
  groupIdDestination: string;
}) => {
  return apiClient
    .put(
      `${BASE_API_URL}/folders/${folderId}/topic_extracts/${extractId}/move-to-group/${groupIdDestination}`,
    )
    .then((response) => response.data);
};
