import React, { useCallback, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Folder } from '../../../services/api/workspace/types';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CheckIcon from '@mui/icons-material/Check';
import styles from './PickFolderModal.module.css';
import { useFocusOnCondition } from '../../../hooks';

export interface PickFolderModalProps {
  open: boolean;
  onSelect: (folderId: string) => void;
  onClose: () => void;
  folders: Folder[];
  onAddFolder: (searchTerm: string) => void;
}

const PickFolderModal: React.FC<PickFolderModalProps> = ({
  open,
  onSelect,
  onClose,
  folders,
  onAddFolder,
}) => {
  const [addingFolder, setAddingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [highlightedFolderName, setHighlightedFolderName] = useState<string | null>(null);

  const inputRef = useFocusOnCondition(addingFolder);

  const resetModalState = useCallback(() => {
    setNewFolderName('');
    setAddingFolder(false);
  }, [setAddingFolder]);

  const handleClose = () => {
    onClose();
    resetModalState();
  };

  const handleListItemClick = (value: string) => {
    onSelect(value);
    handleClose();
  };

  const handleSaveClick = () => {
    if (onAddFolder && newFolderName.trim()) {
      onAddFolder(newFolderName);
      resetModalState();
      setHighlightedFolderName(newFolderName);
      setTimeout(() => {
        setHighlightedFolderName(null);
      }, 800);
    }
  };

  useEffect(() => {
    setAddingFolder(!Boolean(folders.length));
  }, [folders]);

  const title = folders.length ? 'Choose themes' : 'Create a theme';

  return (
    <Dialog
      className={styles.dialogContainer}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <Box className={styles.content}>
        <DialogTitle className={styles.title}>Add to workspace</DialogTitle>
        <Typography className={styles.description}>
          Organize your research by categorizing topics, people, and sponsors into themes on your
          workspace.
        </Typography>
        <Typography>{title}:</Typography>
        <List className={styles.list}>
          {folders.map((folder) => (
            <ListItem disableGutters key={folder.id}>
              <ListItemButton
                className={
                  highlightedFolderName === folder.name ? styles.folderSuccess : styles.folder
                }
                onClick={() => handleListItemClick(folder.id)}
              >
                <FolderOpenIcon className={styles.folderIcon} />
                <ListItemText primary={folder.name} />
                {highlightedFolderName === folder.name && (
                  <CheckIcon className={styles.folderCheckIcon} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem className={styles.addFolderBox}>
            {addingFolder ? (
              <>
                <TextField
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="Enter a theme title..."
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSaveClick();
                    }
                  }}
                  className={styles.textField}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FolderOpenIcon />
                      </InputAdornment>
                    ),
                    inputProps: { style: { padding: '16px 16px 16px 0' } },
                  }}
                />
                <Box className={styles.buttonBox}>
                  <Button
                    size="large"
                    onClick={handleSaveClick}
                    variant="contained"
                    color="primary"
                    className={styles.saveButton}
                  >
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={resetModalState}
                    variant="outlined"
                    className={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                onClick={() => setAddingFolder(true)}
                variant="contained"
                startIcon={<AddIcon className={styles.addIcon} />}
                className={styles.addFolderButton}
              >
                Add theme
              </Button>
            )}
          </ListItem>
        </List>
        <DialogActions className={styles.footer}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PickFolderModal;
