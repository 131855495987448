import React, { useCallback, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button, Collapse, InputAdornment, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import styles from './MoveToModal.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { CombinedExtract } from '../../../services/api/workspace/types';
import { useUserSettingsStore } from '../../../stores';
import { useFocusOnCondition, useToggle } from '../../../hooks';
import { useFetchProjectDetails } from '../../../services/api/workspace/projects/hooks';
import { useCreateFolder } from '../../../services/api/workspace/folders/hooks';
import {
  useMovePersonToAnotherFolder,
  useMovePersonToAnotherGroup,
} from '../../../services/api/workspace/people/hooks';
import {
  useMoveTopicToAnotherFolder,
  useMoveTopicToAnotherGroup,
} from '../../../services/api/workspace/topics/hooks';

export interface MoveToModalProps {
  open: boolean;
  onClose: () => void;
  selectedItem?: CombinedExtract | null;
}

const MoveToModal: React.FC<MoveToModalProps> = ({ open, onClose, selectedItem }) => {
  const { selectedProjectId, selectedFolderId } = useUserSettingsStore();

  const [addingFolder, setAddingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [selectedDestinationItem, setSelectedDestinationItem] = useState<{
    id: string;
    group: boolean;
  }>();
  const [selectedFolderCollapseOpen, toggleSelectedFolderCollapse] = useToggle(true);

  const { data: projectDetails, refetch: refetchProjectDetails } =
    useFetchProjectDetails(selectedProjectId);
  const { mutate: createFolder } = useCreateFolder();
  const { mutate: movePersonToAnotherFolder } = useMovePersonToAnotherFolder();
  const { mutate: movePersonToAnotherGroup } = useMovePersonToAnotherGroup();
  const { mutate: moveTopicToAnotherFolder } = useMoveTopicToAnotherFolder();
  const { mutate: moveTopicToAnotherGroup } = useMoveTopicToAnotherGroup();

  const inputRef = useFocusOnCondition(addingFolder);

  const resetModalState = useCallback(() => {
    setNewFolderName('');
    setAddingFolder(false);
  }, [setAddingFolder]);

  const handleClose = () => {
    onClose();
    resetModalState();
  };

  const handleListItemClick = () => {
    const [moveItemToAnotherGroup, moveItemToAnotherFolder, extractId] =
      selectedItem?.people_extract_id
        ? [movePersonToAnotherGroup, movePersonToAnotherFolder, selectedItem.people_extract_id]
        : [moveTopicToAnotherGroup, moveTopicToAnotherFolder, selectedItem?.topic_extract_id || ''];
    const folderId = selectedFolderId;

    if (selectedDestinationItem?.group) {
      moveItemToAnotherGroup(
        { extractId, folderId, groupIdDestination: selectedDestinationItem.id },
        {
          onSuccess: () => {
            onClose();
            resetModalState();
          },
        },
      );
    } else {
      moveItemToAnotherFolder(
        { extractId, folderId, folderIdDestination: selectedDestinationItem?.id || '' },
        {
          onSuccess: () => {
            onClose();
            resetModalState();
          },
        },
      );
    }
  };

  const handleCreateFolderSave = () => {
    if (newFolderName.trim()) {
      createFolder(newFolderName, { onSuccess: () => refetchProjectDetails() });
      resetModalState();
    }
  };

  return (
    <Dialog
      className={styles.dialogContainer}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <Box className={styles.content}>
        <DialogTitle className={styles.title}>Move {selectedItem?.name}</DialogTitle>

        <List className={styles.list}>
          {projectDetails?.user.folders.map((folder) => (
            <React.Fragment key={folder.id}>
              <ListItem disableGutters>
                {folder.id === selectedFolderId ? (
                  <ListItemButton className={styles.folder} onClick={toggleSelectedFolderCollapse}>
                    <FolderOpenIcon className={styles.folderIcon} />
                    <ListItemText primary={folder.name} />
                    <Box display="flex" alignItems="center">
                      <Typography className={styles.currentLocationLabel}>
                        (Current location)
                      </Typography>
                      {selectedFolderCollapseOpen ? (
                        <KeyboardArrowUpIcon color="primary" />
                      ) : (
                        <KeyboardArrowDownIcon color="primary" />
                      )}
                    </Box>
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    className={`${styles.folder} ${selectedDestinationItem?.id === folder.id ? styles.selectedItem : ''}`}
                    onClick={() => setSelectedDestinationItem({ group: false, id: folder.id })}
                  >
                    <FolderOpenIcon className={styles.folderIcon} />
                    <ListItemText primary={folder.name} />
                  </ListItemButton>
                )}
              </ListItem>

              <Collapse
                in={folder.id === selectedFolderId && selectedFolderCollapseOpen}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {folder.folder_groups.map((group) => (
                    <ListItem key={group.id} disableGutters>
                      <ListItemButton
                        onClick={() => setSelectedDestinationItem({ group: true, id: group.id })}
                        className={`${styles.group}  ${selectedDestinationItem?.id === group.id ? styles.selectedItem : ''}`}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText primary={group.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}

          <ListItem className={styles.addFolderBox}>
            {addingFolder ? (
              <>
                <TextField
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="Enter a theme title..."
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCreateFolderSave();
                    }
                  }}
                  className={styles.textField}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FolderOpenIcon />
                      </InputAdornment>
                    ),
                    inputProps: { style: { padding: '16px 16px 16px 0' } },
                  }}
                />
                <Box className={styles.buttonBox}>
                  <Button
                    size="large"
                    onClick={handleCreateFolderSave}
                    variant="contained"
                    color="primary"
                    className={styles.saveButton}
                  >
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={resetModalState}
                    variant="outlined"
                    className={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => setAddingFolder(true)}
                className={styles.addFolderButton}
              >
                <Typography variant="body2">Add theme</Typography>
              </Button>
            )}
          </ListItem>
        </List>
        <Box display="flex" width="100%"></Box>
        <DialogActions className={styles.footer}>
          <Button onClick={handleClose}>Close</Button>
          <Button
            disabled={!selectedDestinationItem}
            variant="contained"
            onClick={handleListItemClick}
          >
            Move
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default MoveToModal;
