import React, { useState } from 'react';
import { MenuItem, ListItemText, Box, IconButton, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Project } from '../../../services/api/workspace/types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './SelectProjectMenuItem.module.css';
import { useDeleteProject, useUpdateProject } from '../../../services/api/workspace/projects/hooks';
import { useDeleteItemConfirm } from '../../../hooks';
import { noop, truncateText } from '../../../services/utils';

interface SelectProjectMenuItemProps {
  selectedProjectId: string;
  onMenuItemClick: (projectId: string) => void;
  project: Project;
}

const SelectProjectMenuItem: React.FC<SelectProjectMenuItemProps> = ({
  selectedProjectId,
  onMenuItemClick,
  project,
}) => {
  const deleteProjectConfirm = useDeleteItemConfirm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');

  const { mutate: deleteProject } = useDeleteProject();
  const { mutate: updateProject } = useUpdateProject();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProjectName(event.target.value);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    deleteProjectConfirm(
      `Are you sure you want to delete "${project.name}"?`,
      'All research and work done for this event will be permanently deleted. ',
    )
      .then(() => deleteProject(project.id))
      .catch(noop);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setNewProjectName(project.name);
    setIsEditMode(true);
  };

  const handleCancelEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsEditMode(false);
  };

  const handleUpdateProjectName = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (newProjectName === project.name) {
      setIsEditMode(false);
      return;
    }

    if (newProjectName.trim().length) {
      updateProject(
        { projectId: project.id, name: newProjectName },
        { onSuccess: () => setIsEditMode(false) },
      );
    }
  };

  const handleMenuItemClick = () => {
    if (!isEditMode) {
      onMenuItemClick(project.id);
    }
  };

  return (
    <MenuItem
      onKeyDown={(e) => e.stopPropagation()}
      sx={{
        '&:focus': {
          backgroundColor: '#1976D21F',
        },
      }}
      autoFocus={project.id === selectedProjectId}
      key={project.id}
      onClick={handleMenuItemClick}
    >
      {isEditMode ? (
        <TextField
          name="projectName"
          size="small"
          variant="standard"
          fullWidth
          value={newProjectName}
          onChange={handleInputChange}
          inputProps={{ onMouseDown: (e) => e.stopPropagation() }}
        />
      ) : (
        <ListItemText className={styles.projectName} primary={truncateText(project.name, 38)} />
      )}

      <Box>
        {isEditMode ? (
          <>
            <IconButton onClick={handleUpdateProjectName}>
              <CheckIcon className={styles.icon} />
            </IconButton>
            <IconButton onClick={handleCancelEdit}>
              <CloseIcon className={styles.icon} />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleEditClick}>
              <EditIcon className={styles.icon} />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon className={styles.icon} />
            </IconButton>
          </>
        )}
      </Box>
    </MenuItem>
  );
};

export default SelectProjectMenuItem;
