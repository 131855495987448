import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFolderPersonByExtract } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { SnackbarType } from '../../../../../enums';
import { Messages } from '../../../../../constants';

const useDeleteFolderPersonByExtract = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (personExtractId: string) => deleteFolderPersonByExtract(personExtractId),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fullSearch'] });
      showSnackbar(Messages.SUCCESS.FOLDER_PERSON_DELETED, SnackbarType.SUCCESS);
    },
  });
};

export default useDeleteFolderPersonByExtract;
